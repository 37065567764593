import apiConfig from '../../../../apiConfig'
import {
  BIM_USER_MANAGEMENT_PATH,
  CATALOG_DETAILS_PATH,
  CATALOG_IMAGE_BULK_UPLOAD_PATH,
  CATALOG_ITEM_DETAILS_PATH,
  CATALOG_ITEM_MULTI_EDIT_PATH,
  CATALOG_ITEM_NRI_CREATE,
  CATALOG_ITEM_SEARCH_PATH,
  CATALOG_UPDATES_PATH,
  CHANGE_COMM_REPORT,
  CHANGE_GROUP_LIST,
  CHANGE_LOG_COMMUNICATION,
  DESIGN_INITIATIVE_DASHBOARD,
  CONTRACT_EXPIRATION_PATH,
  CREATE_CHANGES,
  DIRECT_REPORT,
  HZN_SOURCING_GROUP_DETAILS_PATH,
  HZN_SOURCING_GROUPS_MULTI_EDIT_PATH,
  HZN_SOURCING_GROUPS_PATH,
  INDIRECT_ITEM_SEARCH,
  UNSPSC_LOOKUP_TOOL_PATH,
  INDIRECT_ITEMS_MULTI_DOC_DOWNLOAD,
  INDIRECT_ITEMS_MULTI_EDIT_PATH,
  ITEM_ATTR_REPORT,
  LOCATIONS_SEARCH_PATH,
  MY_VIEW_DASHBOARD,
  NRI_DETAILS_PATH,
  POTENTIAL_IMPACT_NEW_PART1,
  POTENTIAL_IMPACT_NEW_PART2,
  PROGRAM_LIST_PATH,
  PROJECT_LIST_PATH,
  PROJECT_TEAM_UPDATE_PATH,
  PURCHASE_PATH,
  QMS_VENDOR_TAKEOFF_PATH,
  REASSIGN_WORK,
  RESOURCES_NEEDED_PATH,
  RESOURCES_NEEDED,
  SOLUTION_REQUEST_CREATE,
  SOLUTION_REQUEST_DASHBOARD,
  SOLUTION_REQUEST_DETAILS,
  SPATIO_CREATE_PROJECT_PATH,
  SPATIO_LIST_PROJECTS_PATH,
  TEAM_ASSIGNMENT_PATH,
  TEAM_MANAGEMENT_PATH,
  CHANGE_NOTIFICATIONS_PATH,
  VA_GRID_LIST_PATH,
  DESIGN_INITIATIVE_DETAILS,
  DESIGN_INITIATIVE_TERMINATE,
  VA_EDIT_PATH,
  ZOL_PUBLISHED_BOMS_PATH,
  ZOL_PLANNED_BOM_PATH,
  NRI_ITEM_CREATE_PATH,
  INDIRECT_ITEM_REPORTS,
  NRI_REQUESTS_PATH,
  NRI_REQUEST_PATH,
  DEMAND_TEMPLATES_PATH,
  CPM_ADMIN_PATH,
  ISC_RELATED_DASHBOARD_PATH,
} from '../../../MicroFrontEnds/utility/constants'

import { BimUserManagement } from '../../../MicroFrontEnds/BimUserManagement'
import { CatalogDetails } from '../../../MicroFrontEnds/CatalogDetails'
import { CatalogItemDetails } from '../../../MicroFrontEnds/CatalogItemDetails'
import { CatalogItemMultiEdit } from '../../../MicroFrontEnds/CatalogItemMultiEdit'
import { CatalogItemNriCreate } from '../../../MicroFrontEnds/CatalogItemNriCreate'
import { CatalogItemSearch } from '../../../MicroFrontEnds/CatalogItemSearch'
import { CatalogUpdatesList } from '../../../MicroFrontEnds/CatalogUpdatesList'
import { DesignInitiatives } from '../../../MicroFrontEnds/DesignInitiatives'
import { ContractExpiration } from '../../../MicroFrontEnds/ContractExpiration'
import { constants } from '../utilities/constants'
import { DirectReport } from '../../../MicroFrontEnds/DirectReport'
import { HomePage } from '../../../MicroFrontEnds/HomePage'
import { HznSourcingGroupDetails } from '../../../MicroFrontEnds/HznSourcingGroupDetails'
import { HznSourcingGroups } from '../../../MicroFrontEnds/HznSourcingGroups'
import { HznSourcingGroupsMultiEdit } from '../../../MicroFrontEnds/HznSourcingGroupsMultiEdit'
import { IndirectItemMultiDocDownload } from '../../../MicroFrontEnds/IndirectItemMultiDocDownload/IndirectItemMultiDocDownload'
import { IndirectItemSearch } from '../../../MicroFrontEnds/IndirectItemsSearch'
import { IndirectItemsMultiEdit } from '../../../MicroFrontEnds/IndirectItemsMultiEdit'
import { SolutionRequests } from '../../../MicroFrontEnds/SolutionRequests'
import { ItemAttrCompletionReports } from '../../../MicroFrontEnds/ItemAttrCompletionReports'
import { ItemDetailsDashboard } from '../../../MicroFrontEnds/ItemDetailsDashboard/ItemDetailsDashboard'
import { LocationsSearch } from '../../../MicroFrontEnds/LocationsSearch'
import { PotentialImpact } from '../../../MicroFrontEnds/PotentialImpact'
import { ProgramCreate } from '../../../MicroFrontEnds/ProgramCreate/ProgramCreate'
import { ProgramDetails } from '../../../MicroFrontEnds/ProgramDetails/ProgramDetails'
import { ProgramList } from '../../../MicroFrontEnds/ProgramList/ProgramList'
import { ProjectDetails } from '../../../MicroFrontEnds/ProjectDetails/ProjectDetails'
import { ProjectTeamUpdate } from '../../../MicroFrontEnds/ProjectTeamUpdate'
import { Purchase } from '../../../MicroFrontEnds/Purchase'
import { ReassignWork } from '../../../MicroFrontEnds/ReassignWork'
import { ResourcesNeeded } from '../../../MicroFrontEnds/ResourcesNeeded'
import { SpatioCreateProject } from '../../../MicroFrontEnds/SpatioCreateProject'
import { SpatioListProjects } from '../../../MicroFrontEnds/SpatioListProjects'
import { TeamAssignment } from '../../../MicroFrontEnds/TeamAssignment'
import { TeamsGroupManagement } from '../../../MicroFrontEnds/TeamsGroupManagement'
import { Unauthorized } from '../utilities/Unauthorized'
import { UnspscLookupTool } from '../../../MicroFrontEnds/UnspscLookupTool'
import { ProgramProjectChanges } from '../../../MicroFrontEnds/ProgramProjectChanges'
import VaGridList from '../../../MicroFrontEnds/VaGridList/VaGridList'
import { VaEdit } from '../../../MicroFrontEnds/VaEdit'
import { ZolPublishedBoms } from '../../../MicroFrontEnds/ZolPublishedBoms'
import { ZolPlannedBom } from '../../../MicroFrontEnds/ZolPlannedBom'
import { ItemCreateDashboard } from '../../../MicroFrontEnds/ItemCreateDashboard'
import { IndirectItemReports } from '../../../MicroFrontEnds/IndirectItemReports'
import { IndirectItemVariations } from '../../../MicroFrontEnds/IndirectItemsVariations'
import { CatalogImageBulkUpload } from '../../../MicroFrontEnds/CatalogImageBulkUpload'
import { IndirectItemRequests } from '../../../MicroFrontEnds/IndirectItemsRequests'
import { DemandTemplates } from '../../../MicroFrontEnds/DemandTemplates'
import { CpmAdmin } from '../../../MicroFrontEnds/CpmAdmin'
import { QmsVendorTakeoff } from '../../../MicroFrontEnds/QmsVendorTakeoff/QmsVendorTakeoff'
import { IscRelatedDashboard } from '../../../MicroFrontEnds/IscRelatedDashboard'

export const appRoutesArray = () => {
  const catalogAllowed = [
    apiConfig.entitlements?.catItem?.read,
    apiConfig.entitlements?.catItem?.update,
    apiConfig.entitlements?.catItem?.create,
    apiConfig.entitlements?.ttsAdmin?.create,
  ]
  const catalogDetailsUpdateAllowed = [
    apiConfig.entitlements?.catalog?.read,
    apiConfig.entitlements?.catalog?.update,
    apiConfig.entitlements?.ttsAdmin?.create,
  ]

  const catalogMultiItemEditAllowed = [
    apiConfig.entitlements?.catItem?.update,
    apiConfig.entitlements?.catItem?.create,
    apiConfig.entitlements?.ttsAdmin?.create,
  ]

  const catalogItemNriCreateAllowed = [
    apiConfig.entitlements?.item?.create,
    apiConfig.entitlements?.ttsAdmin?.create,
  ]
  const vendorAssignmentAllowed = [
    apiConfig.entitlements?.vendorAssignment?.read,
    apiConfig.entitlements?.ttsAdmin?.create,
  ]

  const isIpUser = [
    apiConfig.entitlements?.catItem?.read,
    apiConfig.entitlements?.ttsAdmin?.create,
  ]

  const sourcingGroupsAllowed = [
    apiConfig.entitlements?.ttsAdmin?.create,
    apiConfig.entitlements?.sourcing?.create,
    apiConfig.entitlements?.sourcing?.update,
    apiConfig.entitlements?.sourcing?.read,
  ]

  const isSourcingEntitled = [
    apiConfig.entitlements?.ttsAdmin?.create,
    apiConfig.entitlements?.sourcing?.read,
  ]

  const sourcingGroupsDetailsAllowed = [
    apiConfig.entitlements?.ttsAdmin?.create,
    apiConfig.entitlements?.sourcing?.create,
    apiConfig.entitlements?.sourcing?.update,
  ]

  const itemMgtCreate = [
    apiConfig.entitlements?.item?.create,
    apiConfig.entitlements?.tcinRequest?.create,
    apiConfig.entitlements?.tcinRequest?.read,
  ]

  const isDemandAllowed = [
    apiConfig.entitlements?.ttsAdmin?.create,
    apiConfig.entitlements?.demand?.read,
  ]

  const spatioProjectCreateAllowed = [
    apiConfig.entitlements?.spatio?.create,
    apiConfig.entitlements?.spatio?.update,
  ]

  const spatioProjectListAllowed = [
    apiConfig.entitlements?.spatio?.create,
    apiConfig.entitlements?.spatio?.update,
    apiConfig.entitlements?.spatio?.read,
  ]

  const isItemUpdateEntitled = [
    apiConfig.entitlements?.item?.update,
    apiConfig.entitlements?.ttsAdmin?.create,
  ]

  const itemRequestsAllowed = [
    apiConfig.entitlements?.item?.create,
    apiConfig.entitlements?.item?.request,
    apiConfig.entitlements?.tcinRequest?.create,
    apiConfig.entitlements?.tcinRequest?.read,
  ]

  const itemReportsAllowed = [
    apiConfig.entitlements?.ttsAdmin?.create,
    apiConfig.entitlements?.item?.create,
    apiConfig.entitlements?.item?.update,
  ]

  /* The access for External users is controlled using the "isPolAllowed" flag being set to true or false. It will default to no access if the flag is not set to true  */
  return [
    {
      path: CATALOG_ITEM_SEARCH_PATH,
      component: CatalogItemSearch,
      isExact: true,
      allowed: isIpUser,
      protected: true,
    },
    {
      path: `${CATALOG_ITEM_DETAILS_PATH}:id`,
      component: CatalogItemDetails,
      allowed: catalogAllowed,
      protected: true,
    },
    {
      path: `${CATALOG_DETAILS_PATH}:id`,
      component: CatalogDetails,
      allowed: catalogDetailsUpdateAllowed,
      protected: true,
    },
    {
      path: CATALOG_IMAGE_BULK_UPLOAD_PATH,
      component: CatalogImageBulkUpload,
      allowed: catalogDetailsUpdateAllowed,
      protected: true,
    },
    {
      path: CATALOG_UPDATES_PATH,
      component: CatalogUpdatesList,
      allowed: catalogDetailsUpdateAllowed,
      protected: true,
    },
    {
      path: CATALOG_ITEM_MULTI_EDIT_PATH,
      component: CatalogItemMultiEdit,
      allowed: catalogMultiItemEditAllowed,
      protected: true,
    },
    {
      path: CATALOG_ITEM_NRI_CREATE,
      component: CatalogItemNriCreate,
      allowed: catalogItemNriCreateAllowed,
      protected: true,
    },
    {
      path: CONTRACT_EXPIRATION_PATH,
      component: ContractExpiration,
      allowed: isSourcingEntitled,
      protected: true,
    },
    {
      path: HZN_SOURCING_GROUPS_MULTI_EDIT_PATH,
      component: HznSourcingGroupsMultiEdit,
      allowed: sourcingGroupsDetailsAllowed,
      protected: true,
      isExact: true,
    },
    {
      path: HZN_SOURCING_GROUPS_PATH,
      component: HznSourcingGroups,
      allowed: isSourcingEntitled,
      protected: true,
      isExact: true,
    },
    {
      path: `${HZN_SOURCING_GROUP_DETAILS_PATH}/:id`,
      component: HznSourcingGroupDetails,
      allowed: sourcingGroupsAllowed,
      isExact: true,
      protected: true,
    },
    {
      path: HZN_SOURCING_GROUP_DETAILS_PATH,
      component: HznSourcingGroupDetails,
      allowed: sourcingGroupsDetailsAllowed,
      protected: true,
    },
    {
      path: VA_GRID_LIST_PATH,
      component: VaGridList,
      allowed: vendorAssignmentAllowed,
      protected: true,
    },
    {
      path: DEMAND_TEMPLATES_PATH,
      component: DemandTemplates,
      allowed: isDemandAllowed,
      protected: true,
    },
    {
      path: QMS_VENDOR_TAKEOFF_PATH,
      component: QmsVendorTakeoff,
      protected: true,
      isExact: true,
    },
    {
      path: ITEM_ATTR_REPORT,
      component: ItemAttrCompletionReports,
      allowed: isItemUpdateEntitled,
      protected: true,
    },
    {
      path: INDIRECT_ITEM_SEARCH,
      component: IndirectItemSearch,
      isExact: true,
    },
    {
      path: UNSPSC_LOOKUP_TOOL_PATH,
      component: UnspscLookupTool,
      isExact: true,
    },
    {
      path: INDIRECT_ITEMS_MULTI_DOC_DOWNLOAD,
      component: IndirectItemMultiDocDownload,
      isExact: true,
    },
    {
      path: INDIRECT_ITEMS_MULTI_EDIT_PATH,
      component: IndirectItemsMultiEdit,
      allowed: sourcingGroupsAllowed,
      isExact: true,
      protected: true,
    },
    {
      path: INDIRECT_ITEM_REPORTS,
      component: IndirectItemReports,
      allowed: itemReportsAllowed,
      isExact: true,
      protected: true,
    },
    {
      path: `${NRI_DETAILS_PATH}/:id/variations`,
      component: IndirectItemVariations,
      isExact: true,
    },
    {
      path: `${NRI_REQUESTS_PATH}`,
      component: IndirectItemRequests,
      allowed: itemRequestsAllowed,
      isExact: true,
      protected: true,
    },
    {
      path: PROGRAM_LIST_PATH,
      component: ProgramList,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${NRI_DETAILS_PATH}/:id`,
      component: ItemDetailsDashboard,
      protected: false,
    },
    {
      path: NRI_ITEM_CREATE_PATH,
      allowed: itemMgtCreate,
      component: ItemCreateDashboard,
      protected: true,
    },
    {
      path: NRI_REQUEST_PATH,
      allowed: itemMgtCreate,
      component: ItemCreateDashboard,
      protected: true,
    },
    {
      path: `${PROGRAM_LIST_PATH}/create`,
      component: ProgramCreate,
      allowed: [apiConfig.entitlements?.project?.create],
      isExact: true,
      protected: true,
    },
    {
      path: `${PROGRAM_LIST_PATH}/:project_group_id`,
      component: ProgramDetails,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${LOCATIONS_SEARCH_PATH}/:location_id${PROGRAM_LIST_PATH}/:project_group_id`,
      component: ProgramDetails,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${RESOURCES_NEEDED_PATH}${PROGRAM_LIST_PATH}/:project_group_id`,
      component: ProgramDetails,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${CHANGE_COMM_REPORT}${PROGRAM_LIST_PATH}/:project_group_id`,
      component: ProgramDetails,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${PROGRAM_LIST_PATH}/:project_group_id${PROJECT_LIST_PATH}/:project_instance_id`,
      component: ProjectDetails,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${PROJECT_LIST_PATH}/:project_instance_id`,
      component: ProjectDetails,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${LOCATIONS_SEARCH_PATH}/:location_id${PROJECT_LIST_PATH}/:project_instance_id`,
      component: ProjectDetails,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${RESOURCES_NEEDED_PATH}${PROJECT_LIST_PATH}/:project_instance_id`,
      component: ProjectDetails,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${CHANGE_COMM_REPORT}${PROJECT_LIST_PATH}/:project_instance_id`,
      component: ProjectDetails,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: PURCHASE_PATH,
      component: Purchase,
      allowed: [apiConfig.entitlements?.purchase?.create],
      isExact: true,
      allowedForPol: false,
    },
    {
      path: SOLUTION_REQUEST_DASHBOARD,
      component: SolutionRequests,
      isExact: true,
      protected: true,
    },
    {
      path: SOLUTION_REQUEST_CREATE,
      component: SolutionRequests,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${SOLUTION_REQUEST_DETAILS}:id`,
      component: SolutionRequests,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: CHANGE_LOG_COMMUNICATION,
      component: ProgramProjectChanges,
      isExact: true,
      protected: true,
      isPolAllowed: true,
    },
    {
      path: CHANGE_COMM_REPORT,
      component: ProgramProjectChanges,
      isExact: true,
      protected: true,
      isPolAllowed: true,
    },
    {
      path: RESOURCES_NEEDED,
      component: ResourcesNeeded,
      isExact: true,
      protected: true,
    },
    {
      path: TEAM_ASSIGNMENT_PATH,
      component: TeamAssignment,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: TEAM_MANAGEMENT_PATH,
      component: TeamsGroupManagement,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: PROJECT_TEAM_UPDATE_PATH,
      component: ProjectTeamUpdate,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: BIM_USER_MANAGEMENT_PATH,
      component: BimUserManagement,
      isExact: true,
      isPolAllowed: false,
      protected: true,
    },
    {
      path: `${CHANGE_LOG_COMMUNICATION}/:id`,
      component: ProgramProjectChanges,
      isExact: true,
      protected: true,
      isPolAllowed: true,
    },
    {
      path: CREATE_CHANGES,
      component: ProgramProjectChanges,
      isExact: true,
      protected: true,
      isPolAllowed: true,
    },
    {
      path: `${CHANGE_GROUP_LIST}:id`,
      component: ProgramProjectChanges,
      isExact: true,
      protected: true,
      isPolAllowed: true,
    },
    {
      path: MY_VIEW_DASHBOARD,
      component: HomePage,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: DESIGN_INITIATIVE_DASHBOARD,
      component: DesignInitiatives,
      isExact: true,
      protected: true,
    },
    {
      path: `${DESIGN_INITIATIVE_DETAILS}:id`,
      component: DesignInitiatives,
      isPolAllowed: true,
      protected: true,
      isExact: true,
    },
    {
      path: `${DESIGN_INITIATIVE_TERMINATE}:id`,
      component: DesignInitiatives,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${POTENTIAL_IMPACT_NEW_PART1}:conceptId${POTENTIAL_IMPACT_NEW_PART2}:programImpactId`,
      component: PotentialImpact,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: REASSIGN_WORK,
      component: ReassignWork,
      isExact: true,
      protected: false,
    },
    {
      path: `${DIRECT_REPORT}:lanId`,
      component: DirectReport,
      isExact: true,
      protected: false,
    },
    {
      path: constants.unAuthorizedRoute,
      component: Unauthorized,
      isExact: true,
    },
    {
      path: LOCATIONS_SEARCH_PATH,
      component: LocationsSearch,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${LOCATIONS_SEARCH_PATH}/:location_id`,
      component: LocationsSearch,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: CPM_ADMIN_PATH,
      component: CpmAdmin,
      isExact: true,
      isPolAllowed: false,
      protected: true,
    },
    {
      path: `${SPATIO_CREATE_PROJECT_PATH}/:projectId`,
      component: SpatioCreateProject,
      allowed: spatioProjectCreateAllowed,
      isExact: true,
      isPolAllowed: false,
      protected: true,
    },
    {
      path: SPATIO_CREATE_PROJECT_PATH,
      component: SpatioCreateProject,
      allowed: spatioProjectCreateAllowed,
      isExact: true,
      isPolAllowed: false,
      protected: true,
    },
    {
      path: SPATIO_LIST_PROJECTS_PATH,
      component: SpatioListProjects,
      allowed: spatioProjectListAllowed,
      isExact: true,
      isPolAllowed: false,
      protected: true,
    },
    {
      path: CHANGE_NOTIFICATIONS_PATH,
      component: ProgramProjectChanges,
      isExact: true,
      isPolAllowed: true,
      protected: true,
    },
    {
      path: `${VA_EDIT_PATH}:programId`,
      component: VaEdit,
      allowed: vendorAssignmentAllowed,
      protected: true,
      isExact: true,
    },
    {
      path: `${ZOL_PLANNED_BOM_PATH}:demandId/:mode?`,
      component: ZolPlannedBom,
      allowed: isDemandAllowed,
      protected: true,
      isExact: true,
    },
    {
      path: ZOL_PUBLISHED_BOMS_PATH,
      component: ZolPublishedBoms,
      allowed: isDemandAllowed,
      protected: true,
      isExact: true,
    },
    {
      path: ISC_RELATED_DASHBOARD_PATH,
      component: IscRelatedDashboard,
      protected: true,
      isExact: true,
    },
  ]
}
