import {
  CATALOG_DETAILS_PATH,
  CATALOG_ITEM_DETAILS_PATH,
  CATALOG_ITEM_MULTI_EDIT_PATH,
  CATALOG_ITEM_NRI_CREATE,
  CATALOG_ITEM_SEARCH_PATH,
  CATALOG_UPDATES_PATH,
  CHANGE_LOG_COMMUNICATION,
  HOME_PATH,
  HZN_SOURCING_GROUPS_MULTI_EDIT_PATH,
  HZN_SOURCING_GROUP_DETAILS_PATH,
  HZN_SOURCING_GROUPS_PATH,
  INDIRECT_ITEMS_MULTI_EDIT_PATH,
  INDIRECT_ITEM_SEARCH,
  NRI_DETAILS_PATH,
  SOLUTION_REQUEST_CREATE,
  SOLUTION_REQUEST_DASHBOARD,
  PROGRAM_LIST_PATH,
  PROJECT_LIST_PATH,
  PURCHASE_PATH,
  INDIRECT_ITEMS_MULTI_DOC_DOWNLOAD,
  LOCATIONS_SEARCH_PATH,
  CONTRACT_EXPIRATION_PATH,
  VA_EDIT_PATH,
  VA_GRID_LIST_PATH,
  ZOL_PLANNED_BOM_PATH,
  ZOL_PUBLISHED_BOMS_PATH,
  NRI_ITEM_CREATE_PATH,
  INDIRECT_ITEM_REPORTS,
  INDIRECT_ITEMS_VARIATIONS_PATH,
  CATALOG_IMAGE_BULK_UPLOAD_PATH,
  NRI_REQUEST_PATH,
  NRI_REQUESTS_PATH,
  DEMAND_TEMPLATES_PATH,
  QMS_VENDOR_TAKEOFF_PATH,
  ISC_RELATED_DASHBOARD_PATH,
} from './constants'

interface GenericObjectIndexer {
  [key: string]: any
}

export const mfeNavUrls: GenericObjectIndexer = {
  CatalogDetails: CATALOG_DETAILS_PATH,
  CatalogImageBulkUpload: CATALOG_IMAGE_BULK_UPLOAD_PATH,
  CatalogItemDetails: CATALOG_ITEM_DETAILS_PATH,
  CatalogItemMultiEdit: CATALOG_ITEM_MULTI_EDIT_PATH,
  CatalogItemNriCreate: CATALOG_ITEM_NRI_CREATE,
  CatalogItemSearch: CATALOG_ITEM_SEARCH_PATH,
  CatalogUpdates: CATALOG_UPDATES_PATH,
  ChangeLogCommunication: CHANGE_LOG_COMMUNICATION,
  ContractExpiration: CONTRACT_EXPIRATION_PATH,
  Home: HOME_PATH,
  HznSourcingGroups: HZN_SOURCING_GROUPS_PATH,
  HznSourcingGroupsMultiEdit: HZN_SOURCING_GROUPS_MULTI_EDIT_PATH,
  HznSourcingGroupDetails: HZN_SOURCING_GROUP_DETAILS_PATH,
  IndirectItemsMultiEdit: INDIRECT_ITEMS_MULTI_EDIT_PATH,
  IndirectItemVariations: INDIRECT_ITEMS_VARIATIONS_PATH,
  IndirectItemSearch: INDIRECT_ITEM_SEARCH,
  IndirectItemMultiDocDownload: INDIRECT_ITEMS_MULTI_DOC_DOWNLOAD,
  IndirectItemReports: INDIRECT_ITEM_REPORTS,
  IntakeDetails: SOLUTION_REQUEST_CREATE,
  NRIDetails: NRI_DETAILS_PATH,
  NriItemCreate: NRI_ITEM_CREATE_PATH,
  NriItemRequest: NRI_REQUEST_PATH,
  NriItemRequestsDashboard: NRI_REQUESTS_PATH,
  ProgramListPath: PROGRAM_LIST_PATH,
  ProjectListPath: PROJECT_LIST_PATH,
  Purchase: PURCHASE_PATH,
  QmsVendorTakeoff: QMS_VENDOR_TAKEOFF_PATH,
  IscRelatedDashboard: ISC_RELATED_DASHBOARD_PATH,
  SolutionRequestDashboard: SOLUTION_REQUEST_DASHBOARD,
  LocationSearchPath: LOCATIONS_SEARCH_PATH,
  VaEdit: VA_EDIT_PATH,
  VaList: VA_GRID_LIST_PATH,
  ZolPlannedBom: ZOL_PLANNED_BOM_PATH,
  ZolPublishedBoms: ZOL_PUBLISHED_BOMS_PATH,
  DemandTemplates: DEMAND_TEMPLATES_PATH,
}

export const getAnchorUrl = (mfeName: string, id: string = '') => {
  const url = id ? mfeNavUrls[mfeName] + id : mfeNavUrls[mfeName]
  return url
}

export const deriveBasePath = () => {
  const location = window.location.pathname
  return `/${location.split('/')[1]}`.replace('//', '/')
}
