import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const IscRelatedDashboard = () => {
  return (
    <MicroFrontEnd
      name="IscRelatedDashboard"
      src={apiConfig.microFrontEnds.IscRelatedDashboard}
    />
  )
}

export default IscRelatedDashboard
