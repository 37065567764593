export const HOME_LABEL = 'Home'
export const HOME_PATH = '/'

export const CATALOG_PARENT_LABEL = 'Procurement Services'
export const CATALOG_PARENT_SUBLABEL = 'Sourcing, Planning, Procurement'

export const QMS_VENDOR_TAKEOFF_PATH = '/qms-vendor-takeoff/'
export const QMS_VENDOR_TAKEOFF_LABEL = 'Quote Manager'

export const ISC_PARENT_LABEL = 'Indirect Service Catalog'
export const ISC_PARENT_SUBLABEL = 'Catalog Vendors, Locations, Items'

export const ISC_RELATED_DASHBOARD_PATH = '/isc-related-dashboard'
export const ISC_RELATED_DASHBOARD_LABEL = 'Related Data Dashboard '

export const CATALOG_DETAILS_PATH = '/catalog-details/'
export const CATALOG_DETAILS_LABEL = 'Catalog Details'
export const CATALOG_IMAGE_BULK_UPLOAD_PATH = '/catalog-image-bulk-upload/'
export const CATALOG_IMAGE_BULK_UPLOAD_LABEL = 'Catalog Image Bulk Upload'
export const CATALOG_ITEM_DETAILS_LABEL = 'Catalog Item Details'
export const CATALOG_ITEM_DETAILS_PATH = '/catalog-item-details/'
export const CATALOG_ITEM_SEARCH_PATH = '/catalog-item-search'
export const CATALOG_ITEM_SEARCH_LABEL = 'Catalog Item Search'
export const CATALOG_ITEM_SEARCH_ASSIGN_LABEL =
  'Catalog Item Search - Assign TCIN'
export const CATALOG_ITEM_SEARCH_MULTI_EDIT_LABEL =
  'Catalog Item Search - Multi-Edit'
export const CATALOG_UPDATES_PATH = '/catalog-search'
export const CATALOG_UPDATES_LABEL = 'Catalog Search'
export const CATALOG_ITEM_MULTI_EDIT_PATH = '/catalog-item-multi-edit'
export const CATALOG_ITEM_MULTI_EDIT_LABEL = 'Catalog Item Multi Edit'
export const CATALOG_ITEM_NRI_CREATE = '/catalog-item-nri-create'
export const CATALOG_ITEM_NRI_CREATE_LABEL = 'Catalog Item Search - Create TCIN'
export const DEMAND_PLANNING_PARENT_LABEL = 'Demand Details'
export const DEMAND_PLANNING_PARENT_SUBLABEL = 'Demand Planning tools'

export const HZN_SOURCING_GROUP_DETAILS_PATH = '/sourcing-group-details'
export const HZN_SOURCING_GROUPS_PATH = '/sourcing-groups'
export const HZN_SOURCING_GROUPS_LABEL = 'Sourcing Groups'
export const HZN_SOURCING_GROUPS_MULTI_EDIT_LABEL = 'Edit Sourcing Groups'
export const HZN_SOURCING_GROUPS_MULTI_EDIT_PATH = '/sourcing-groups/edit'
export const VA_GRID_LIST_LABEL = 'Vendor Assignments'
export const VA_GRID_LIST_PATH = '/vendor-assignments'
export const DEMAND_TEMPLATES_LABEL = 'Demand Templates'
export const DEMAND_TEMPLATES_PATH = '/demand-templates'
export const CONTRACT_EXPIRATION_LABEL = 'Reports: Contract Expiration'
export const CONTRACT_EXPIRATION_PATH = '/contract-expiration-report'

export const INDIRECT_ITEM_SEARCH = '/tcin-item-search'
export const INDIRECT_ITEM_PARENT_LABEL = 'Indirect Item Management'
export const INDIRECT_ITEM_SEARCH_LABEL = 'TCIN Item Search'
export const INDIRECT_ITEMS_MULTI_EDIT_PATH =
  INDIRECT_ITEM_SEARCH + '/edit-items'
export const INDIRECT_ITEMS_MULTI_EDIT_LABEL = 'TCIN Item Search - Multi-Edit'
export const INDIRECT_ITEMS_MULTI_DOC_DOWNLOAD =
  '/indirect_item_search/multidoc-download'
export const INDIRECT_ITEMS_MULTI_DOC_DOWNLOAD_LABEL = 'Download Documents'
export const INDIRECT_ITEM_REPORTS = '/nri-reports'
export const INDIRECT_ITEM_REPORTS_LABEL = 'Reports: NRI'

export const UNSPSC_LOOKUP_TOOL_LABEL = 'UNSPSC/Commodity Codes'
export const UNSPSC_LOOKUP_TOOL_PATH = '/unspsc-commodity-codes'
export const PURCHASE_PATH = '/purchase'
export const PURCHASE_LABEL = 'Purchase'

export const PROGRAM_LABEL = 'Programs'
export const PROGRAM_SUB_LABEL = 'Implementation of work'
export const PROGRAM_LIST_LABEL = 'Program List'
export const PROGRAM_LIST_PATH = '/programs'
export const PROJECT_LIST_PATH = '/projects'

export const MY_VIEW_DASHBOARD = '/'
export const DIRECT_REPORT = '/direct-report/'
export const REASSIGN_WORK = '/reassign-work'
export const SOLUTION_REQUEST_CREATE = '/intake-create'
export const SOLUTION_REQUEST_DETAILS = '/intake-details/'
export const CHANGE_LOG_COMMUNICATION = '/change-log-communication'
export const CREATE_CHANGES = '/create-changes'
export const CHANGE_GROUP_LIST = '/change-group/'
export const RESOURCES_NEEDED = '/resources-needed'
export const SOLUTION_REQUEST_DASHBOARD = '/intake-dashboard'
export const DESIGN_INITIATIVE_DASHBOARD = '/concept-dashboard'
export const DESIGN_INITIATIVE_DETAILS = '/concept-details/'
export const DESIGN_INITIATIVE_TERMINATE = '/concept-terminate/'
export const POTENTIAL_IMPACT_NEW_PART1 = '/concept/'
export const POTENTIAL_IMPACT_NEW_PART2 = '/potential-impact-new-program/'
export const CHANGE_COMM_REPORT = '/issued-changes'
export const NRI_DETAILS_LABEL = 'TCIN Item Details'
export const NRI_CREATE_LABEL = 'TCIN Item Create'
export const NRI_DETAILS_PATH = '/tcin-item-details'
export const NRI_REQUESTS_PATH = '/tcin-item-requests'
export const NRI_REQUESTS_LABEL = 'TCIN Item Requests'
export const NRI_ITEM_CREATE_LABEL = 'TCIN Item Create'
export const NRI_ITEM_CREATE_PATH = '/tcin-item-create'
export const NRI_REQUEST_PATH = '/tcin-item-request'
export const NRI_REQUEST_LABEL = 'TCIN Item Request'
export const INDIRECT_ITEMS_VARIATIONS_PATH = NRI_DETAILS_PATH + '/variations'
export const INDIRECT_ITEMS_VARIATIONS_LABEL = 'Variations'

export const INTAKE_HDR_LABEL = 'Intake'
export const INTAKE_SUB_LABEL = 'Request new design or project'
export const REQUEST_CREATE_LABEL = 'Create Request'
export const REQUEST_LIST_LABEL = 'Request List'
export const CONCEPT_HDR_LABEL = 'Design Initiative'
export const CONCEPT_SUB_LABEL = 'Design of new ideas'
export const DESIGN_INITIATIVE_LIST = 'Design Initiative List'
export const CHANGE_COMM_REPORT_LABEL = 'Issued Changes'

export const ITEM_ATTR_REPORT = '/item-health-report'
export const ITEM_ATTR_REPORT_LABEL = 'Reports: Item Health'

export const SOURCE_TYPE_INTAKE = 'intake'
export const SOURCE_TYPE_CONCEPT = 'concept'

export const TEAMS_LABEL = 'Teams'
export const TEAMS_SUB_LABEL = 'Team Assignments'
export const TEAM_ASSIGNMENT = 'Project Assign'
export const TEAM_MANAGEMENT_LABEL = 'Team Management'
export const RES_NEEDED_HDR_LABEL = 'Resources Needed'
export const TEAM_ASSIGNMENT_PATH = '/project-assign'
export const TEAM_MANAGEMENT_PATH = '/team-management'
export const RESOURCES_NEEDED_PATH = '/resources-needed'
export const BIM_USER_MANAGEMENT_PATH = '/bim-user-management'
export const BIM_USER_MANAGEMENT_LABEL = 'BIM360 User Management'
export const PROJECT_TEAM_UPDATE_LABEL = 'Project Team Update'
export const PROJECT_TEAM_UPDATE_PATH = '/project-team-update'

export const LOCATIONS_SEARCH_LABEL = 'Locations'
export const LOCATIONS_SEARCH_PATH = '/locations'

export const SPATIO_HDR_LABEL = 'Corporate Real Estate'
export const SPATIO_SUB_LABEL = 'Create or list CRE projects'
export const SPATIO_CREATE_PROJECT_LABEL = 'Create Project'
export const SPATIO_LIST_PROJECTS_LABEL = 'List Projects'
export const SPATIO_CREATE_PROJECT_PATH = '/cre-create-project'
export const SPATIO_LIST_PROJECTS_PATH = '/cre-list-projects'

export const MILESTONE_MANAGEMENT_LABEL = 'Milestone Management'
export const MILESTONE_MANAGEMENT_PATH = '/milestone-management'

export const CHANGE_NOTIFICATIONS_PATH = '/change-notifications'

export const VA_PARENT_LABEL = 'Vendor Assignment'
export const VA_PARENT_SUBLABEL = 'Manage Vendor Assignment'

export const VA_EDIT_PATH = '/edit-vendor-assignment/'
export const VA_EDIT_LABEL = 'Edit Vendor Assignment'

export const ZOL_PLANNED_BOM_PATH = '/planned-bom-demand/'
export const ZOL_PLANNED_BOM_LABEL = 'Planned BOM Demand'
export const ZOL_PUBLISHED_BOMS_PATH = '/demand-planning/'
export const ZOL_PUBLISHED_BOMS_LABEL = 'Demand Planning'

export const ADMIN = 'Admin'
export const ADMIN_SUB_LABEL = 'Admin functionalities in Skylight'
export const CPM_ADMIN = 'CPM Admin'
export const CPM_ADMIN_PATH = '/cpm-admin'
